import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import {Grid, Hidden, Typography} from "@material-ui/core";

export class BesidesLoop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {besides : "",
            besidesList: ["aspiring researcher.",
                "machine learning enthusiast.",
                "amateur climber.",
                "calisthenics athlete.",
                "karate black belt holder."],
            preposition: "",
            prepositionList: ["an", "a", "an", "a", "a"]};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.updateText(),
            1500
        );
        this.updateText()
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    updateText() {
        let besidesText = this.state.besidesList.shift()
        let newBesidesList = this.state.besidesList.concat(besidesText)
        let prepositionText = this.state.prepositionList.shift()
        let newPrepositionList = this.state.prepositionList.concat(prepositionText)

        this.setState({
            besides: besidesText,
            besidesList: newBesidesList,
            preposition: prepositionText,
            prepositionList: newPrepositionList
        });
    }

    render() {
        const grid_item = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        };
        return (
            <ThemeProvider theme={this.props.theme}>
                <div>
                    <Grid
                        //style={{backgroundColor: 'yellowgreen'}}
                        container
                        padding={1}
                    >
                        <Grid item xs={12} md style={grid_item}>
                            <Typography noWrap variant="h5">Besides, I am {this.state.preposition}</Typography>
                            <Hidden smDown>
                                <span style={{width: '10px'}}/>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md style={grid_item}>
                            <Typography style={{color: '#f57c00'}} noWrap variant="h5"> {this.state.besides}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>
        );
    }
}