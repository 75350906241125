import React from "react";
import {Container, Image} from "react-bootstrap";
import './ContactMe.css'
import {SendMessage} from "./MessageMe";
import posed from "react-pose";
import {Typography} from "@material-ui/core";

const SmallIconContainer = posed.div({
    default: {
        y: 0,
        x: 0,
        scale: 1,
    },
    active: {
        y: 0,
        x: 10,
        transition: {duration: 300},
        scale: 1,
    }
});

class ContactMeIcon extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isActive : false
        };
    }
    render() {
        return(
            <SmallIconContainer
                className='link-item'
                onClick={() => {
                    this.setState({isActive : true});
                    setTimeout(() => {
                        this.setState({isActive : false});
                    }, 300);
                    window.open(this.props.link, '_self');
                }}
                onMouseEnter={() => this.setState({isActive : true})}
                onMouseLeave={() => this.setState({isActive : false})}
                pose={this.state.isActive ? 'active' : 'default'}
            >
                <link/>

                <Image className='link-icon' src={this.props.iconSrc} height='30px' />
                <Typography variant='h5' className='link-text'>
                    {this.props.text}
                </Typography>
            </SmallIconContainer>
        );
    }
}

export class ContactMe extends React.Component{
    render() {
        return(
            <Container
                style={{padding: '1rem'}}
            >
                <Typography variant='h2'>
                    Contact Me
                </Typography>
                <br/>
                <Container className='contactme-block'>
                    <div className='contactme-address'>
                        <SendMessage/>
                    </div>

                    <div className='contactme-links'>
                        <ContactMeIcon
                            iconSrc='/icons/attherate.png'
                            text='bg1u17@soton.ac.uk'
                            link='mailto:bg1u17@soton.ac.uk'
                        />
                        <ContactMeIcon
                            iconSrc='/icons/skype.png'
                            text='@gucsi.balint'
                            link='skype:gucsi.balint?chat'
                        />
                        <ContactMeIcon
                            iconSrc='/icons/linkedin.png'
                            text='LinkedIn'
                            link='https://www.linkedin.com/in/balint-gucsi'
                        />
                        <ContactMeIcon
                            iconSrc='/icons/github.png'
                            text='BalintGucsi'
                            link='https://github.com/BalintGucsi'
                        />
                        <ContactMeIcon
                            iconSrc='/icons/scholar.png'
                            text='Google Scholar'
                            link='https://scholar.google.com/citations?user=StIGACwAAAAJ'
                        />
                        <ContactMeIcon
                            iconSrc='/icons/home.png'
                            text='Southampton, UK'
                            link='https://goo.gl/maps/Fs2tCUFYCPo1PxMe9'
                        />
                    </div>
                </Container>
            </Container>

        );
    }
}