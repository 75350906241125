import React from "react";
import {Container, Image} from "react-bootstrap";
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

let banner_theme = createMuiTheme();
banner_theme = responsiveFontSizes(banner_theme, {
    breakpoints: ['xs','sm', 'md', 'lg','xl'],
    factor: 5
});

function AboutMeLine(props){
    return(
        <Container style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start'
        }}>

            <Image src={props.imgSrc} width='30px' style={{marginRight: '10px'}} />
            <Typography variant='h6' >
                {props.text}
            </Typography>
        </Container>
    );
}

export class Banner extends React.Component{
    render() {
        return(
            <ThemeProvider theme={banner_theme}>
                <Container
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '1rem'
                    }}
                >

                    <Typography variant='h2' >
                        Currently...
                    </Typography>
                    <br/>
                    <div>
                        <AboutMeLine
                            text='Pursuing the 3rd year of my iPhD degree at MINDS CDT in Artificial Intelligence.'
                            imgSrc='/icons/graduation-cap.png'
                        />
                        <br/>
                        <AboutMeLine
                            text='Working on projects concerning the topic of User-Aware Human-Robot Collaborative Learning.'
                            imgSrc='/icons/programmer-computer.png'
                        />
                        <br/>
                        <AboutMeLine
                            text="Involved in interdisciplinary academic research using the Pal Robotics TIAGo lifesize robot."
                            imgSrc='/icons/robot.png'
                        />
                        <br/>
                        <AboutMeLine
                            text='Following research interests in AI and ML, with present focus on Multiarmed Bandits and Reinforcement Learning.'
                            imgSrc='/icons/learning-machine-ai.png'
                        />
                    </div>
                </Container>
            </ThemeProvider>
        );
    }
}