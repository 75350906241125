import React from 'react';
import posed from 'react-pose';

import {Image} from "react-bootstrap";
import './contacts.css'
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";

const IconContainer = posed.div({
    default: {
        y: 0,
        scale: 1,
    },
    elevated: {
        y: -10,
        transition: {duration: 300},
        scale: 1,
    }
});

class AnimatedIcon extends React.Component{
    constructor(props) {
        super(props);
        this.state = { isElevated: false };
    }

    render() {
        return(
                <IconContainer
                    className="box"
                    style={{
                        width: '80%',
                        maxWidth: '50px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        this.setState({isElevated : true});
                        setTimeout(() => {
                                this.setState({isElevated : false});
                            }, 200);
                        window.open(this.props.link, '_self');
                    }}
                    onMouseEnter={() => this.setState({isElevated : true})}
                    onMouseLeave={() => this.setState({isElevated : false})}
                    pose={this.state.isElevated ? 'elevated' : 'default'}
                >
                    <Image src={this.props.imgSrc} width='100%' />
                </IconContainer>
        );
    }
}

class ContactIcons extends React.Component{
    render() {
        const grid_item = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        };

        return (
                <Grid container spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="center"
                >

                    <Hidden smDown>
                        <Grid item md={4} lg={4}/>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid xs={2} sm={3} item/>
                    </Hidden>
                    <Grid item xs sm md lg style={grid_item}>
                            <AnimatedIcon
                                className='contact-icon'
                                imgSrc='/icons/attherate.png'
                                link='mailto:bg1u17@soton.ac.uk'
                            />
                    </Grid>
                    <Grid item xs sm md lg style={grid_item}>
                        <AnimatedIcon
                            className='contact-icon'
                            imgSrc='/icons/skype.png'
                            link='skype:gucsi.balint?chat'
                        />
                    </Grid>
                    <Grid item xs sm md lg style={grid_item}>
                        <AnimatedIcon
                            className='contact-icon'
                            imgSrc='/icons/linkedin.png'
                            link='https://www.linkedin.com/in/balint-gucsi'
                        />
                    </Grid>
                    <Grid item xs sm md lg style={grid_item}>
                        <AnimatedIcon
                            className='contact-icon'
                            imgSrc='/icons/github.png'
                            link='https://github.com/BalintGucsi'
                        />
                    </Grid>
                    <Grid item xs sm md lg style={grid_item}>
                        <AnimatedIcon
                            className='contact-icon'
                            imgSrc='/icons/scholar.png'
                            link='https://scholar.google.com/citations?user=StIGACwAAAAJ'
                        />
                    </Grid>
                    <Hidden mdUp>
                        <Grid item xs={2} sm={3}/>
                    </Hidden>
                    <Hidden smDown>
                        <Grid item md={4} lg={4}/>
                    </Hidden>
                </Grid>

        );
    }
}
export default ContactIcons;