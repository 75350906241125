import React from 'react';
import './sections.css';
import {Image, Nav, Navbar} from "react-bootstrap";
import { Link, animateScroll as scroll } from "react-scroll";
import TimelineComponent2 from "./timeline2";
import {Publications} from "./publications";
import {ContactMe} from "./ContactMe";
import posed from "react-pose";
import {Welcome} from "./Welcome";
import {Typography} from "@material-ui/core";
// import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createTheme } from '@material-ui/core/styles';
import {responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import {Banner} from "./Banner";

import ReactGA from 'react-ga';

function initializeReactGA() {
    ReactGA.initialize('UA-180309547-1');
    ReactGA.pageview('/homepage');
}

initializeReactGA()

function Navigation(){
    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky='top'>
            {/*<Navbar.Brand href="welcome">Balint Gucsi</Navbar.Brand>*/}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className='m-auto'>
                    {/*<Nav.Link>*/}
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="welcome"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                            Home
                        </Link>
                    {/*</Nav.Link>*/}
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="aboutme"
                            spy={true}
                            smooth={true}
                            offset={-55}
                            duration={500}
                        >
                            Currently
                        </Link>
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="education"
                            spy={true}
                            smooth={true}
                            offset={-55}
                            duration={500}
                        >
                            Education
                        </Link>
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="experience"
                            spy={true}
                            smooth={true}
                            offset={-55}
                            duration={500}
                        >
                            Experience
                        </Link>
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="publications"
                            spy={true}
                            smooth={true}
                            offset={-55}
                            duration={500}
                        >
                            Publications
                        </Link>
                        <Link
                            className="nav-item"
                            activeClass="active"
                            to="contactme"
                            spy={true}
                            smooth={true}
                            offset={-55}
                            duration={500}
                        >
                            Contact Me
                        </Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

const TopButtonContainer = posed.div({
    default: {
        y: 0,
        scale: 1,
    },
    active: {
        y: -10,
        transition: {duration: 300},
        scale: 1,
    }
});

class TopButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {isActive: false};
    }
    render() {
        return(
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#eceff1'
                }}
            >
                <TopButtonContainer
                    style={{
                        width: '60px',
                        justifyContent: 'center',
                        marginTop: '20px',
                        marginBottom: '10px',
                        cursor: 'pointer'
                    }}
                    onClick={scroll.scrollToTop}
                    onMouseEnter={() => this.setState({isActive : true})}
                    onMouseLeave={() => this.setState({isActive : false})}
                    pose={this.state.isActive ? 'active' : 'default'}
                >

                    <Image src='/icons/expand_less.png' width='100%' />
                </TopButtonContainer>
            </div>
        );
    }
}


function Copyright(){
    return(
        <div
            style={{
                backgroundColor: '#424242',
                color: 'white',
                textAlign: 'center'
            }}
        >
            <Typography>
                Copyright {new Date().getFullYear()} Balint Gucsi
            </Typography>
        </div>
    );
}

export class Section extends React.Component {
    render() {
        return (
            <div id={this.props.id}
                 style={{
                     backgroundColor: this.props.backgroundColor,
                     marginTop: this.props.marginTop,
                     paddingBottom: this.props.paddingBottom,
                     paddingTop: this.props.paddingTop
                 }}
            >
                {this.props.content}
            </div>
        );
    }
}

let theme = createTheme({
    typography:{
        h2:{
            color: 'black'
        }
    }
});
theme = responsiveFontSizes(theme, {
    breakpoints: ['xs','sm', 'md', 'lg','xl'],
    factor: 5
});



class Sections extends React.Component{
    render() {
        return(
            <ThemeProvider theme={theme}>
            <div style={{backgroundColor:'white', color: ''}}>
                <Navigation/>
                <Section
                    content={<Welcome/>}
                    id="welcome"
                    backgroundColor='white'
                />

                <Section
                    content={<Banner/>}
                    id="aboutme"
                    backgroundColor='#eceff1'
                />

                <TimelineComponent2/>

                <Section
                    content={<Publications/>}
                    id="publications"
                    paddingTop='4vh'
                    paddingBottom='8vh'
                />

                <Section
                    content={<ContactMe/>}
                    id="contactme"
                    backgroundColor='#eceff1'
                    paddingTop='4vh'
                />
                <TopButton/>
                <Copyright/>
            </div>
            </ThemeProvider>

        );
    }
}

export default Sections;
