import React from "react";
import Paper from "@material-ui/core/Paper";
import {Form} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";

export class SendMessage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            recaptcha_warning: false,
            message_sent: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.recaptchaRef = React.createRef();
    }

    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({message_sent: false});

        const recaptchaValue = this.recaptchaRef.current.getValue();

        // If Recaptcha has been completed the request is sent
        // EmailJS performs the backend verification

        if (recaptchaValue) {
            const { name, email, message } = this.state;
            const templateParams = {
                name: name,
                message: message,
                email: email,
                'g-recaptcha-response': recaptchaValue
            };
            emailjs.send("BG _bot", "template_scln5pp", templateParams, 'user_kksU6O4sRBexfgBm6tYHK')
                .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            this.setState(
                {recaptcha_warning: false,
                      message_sent: true});
            this.resetForm();
        }
        else{
            this.setState({recaptcha_warning: true});
        }
    };

    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
        });
        this.recaptchaRef.current.reset();
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return(
            <Paper elevation={3}>
                <Form style={{padding: '1rem'}} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onChange={this.handleChange}
                            value={this.state.email}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name="name"
                            type="name"
                            placeholder="Enter your name"
                            onChange={this.handleChange}
                            value={this.state.name}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            name="message"
                            as="textarea"
                            rows="4"
                            placeholder="Enter Your message here"
                            onChange={this.handleChange}
                            value={this.state.message}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formRecaptha">
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey="6LeYWM8ZAAAAAOUXb9xMRpK7krWQ7zoAylmj-uxW"
                        />
                        {this.state.recaptcha_warning&&<Alert severity="warning">Please check the Recaptcha before sending your message!</Alert>}
                    </Form.Group>
                    <Button type='submit' variant="outlined" color='default'>
                        Send
                    </Button>
                </Form>
                {this.state.message_sent&&<Alert severity="success">Your message has been successfully sent!</Alert>}
            </Paper>
        );
    }
}