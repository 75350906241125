import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import {Timeline} from "rsuite";
import Paper from "@material-ui/core/Paper";
import './timeline.css'
import {Container, Image} from "react-bootstrap";
import posed from "react-pose";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import {Hidden, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Section} from "./sections";
import Link from "@material-ui/core/Link";


const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
                    },
    },
    expanded: {},
})(MuiAccordion);


const IconContainer = posed.div({
    default: {
        scale: 1,
        paddingTop: 30,
        paddingBottom: 10,
    },
    zoomed: {
        transition: {duration: 300},
        scale: 1.2,
        paddingBottom: 20,
        paddingTop: 20,
    }
});

function ExpandIcon (props){
    return(
        <div style={{
            textAlign: 'center',
        }}>
            {props.toExpand&&
            <ExpandMoreOutlinedIcon/>}
        </div>
    );

}

function TimelineImage(props){
    return(
        <IconContainer
            style={{paddingLeft: 10,
                paddingTop: 30,
                paddingBottom: 10,
                paddingRight: 10,
            }}
            className='timeline-content-image'
            pose={props.expanded === props.id ? 'zoomed' : 'default'}
        >
            <Image
                className='timeline-item-icon'
                src={props.imgSrc}
                roundedCircle
                width='100%'
            />
        </IconContainer>
    );
}

function TimelineText(props){
    return(
        <Accordion
            className='timeline-content-text'
            square
            expanded={props.expanded === props.id}
        >
            <AccordionSummary>
                <Typography variant='h3'>
                    {props.title}
                </Typography>
                <Typography variant='h4'>
                    {props.subtitle}
                </Typography>
                <br/>
                <Typography variant='h5'>
                    {props.intro}
                </Typography>

            </AccordionSummary>
            <AccordionDetails>
                {props.content}
            </AccordionDetails>
        </Accordion>
    );
}

class TimelineContent extends React.Component{

    render() {
        const grid_item = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        };
        return(
            <Paper  elevation={3}>
                <div className='timeline-content'>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item sm={12} md={3} style={grid_item}>
                            <TimelineImage
                                expanded={this.props.expanded}
                                id={this.props.id}
                                imgSrc={this.props.imgSrc}
                            />
                        </Grid>
                        <Grid item sm={12} md={9} style={grid_item}>
                            <TimelineText
                                expanded={this.props.expanded}
                                id={this.props.id}
                                title={this.props.title}
                                subtitle={this.props.subtitle}
                                intro={this.props.intro}
                                content={this.props.content}
                            />
                        </Grid>
                    </Grid>
                </div>
                <ExpandIcon toExpand={this.props.expanded !== this.props.id}/>
            </Paper>
        );
    }
}

class EducationTimeline extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'panel1'
        };
    }

    render() {
        return(
            <Timeline className={`custom-timeline-2 ${this.props.screenSize}`} align={"left"} endless>
                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2021 - Now</Typography>}
                    className={this.state.expanded === 'panel1' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel1'})}
                    onClick={() => this.setState({expanded : 'panel1'})}
                >
                    <TimelineContent
                        id='panel1'
                        title='iPhD in Machine Intelligence for Nano-Electronic Devices and Systems'
                        subtitle="UKRI Centre for Doctoral Training in Machine Intelligence for Nano-Electronic Devices and Systems (MINDS CDT), University of Southampton, UK"
                        intro='Researching the topic of User-Aware Human-Robot Collaborative Learning, supervised by Danesh Tarapore, Bing Chu (University of Southampton) and Long Tran-Thanh (University of Warwick).'
                        content={
                            <div>
                                <Typography>
                                    Currently in the 3rd year of my iPhD degree investigating topics concerning User-Aware Human-Robot Collaborative Learning, Human-Agent Interactions and Social Robotics, while working with the Pal Robotics TIAGo robot.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/UniversityOfSouthampton.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2017 - 2021</Typography>}
                    className={this.state.expanded === 'panel2' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel2'})}
                    onClick={() => this.setState({expanded : 'panel2'})}
                >
                    <TimelineContent
                        id='panel2'
                        title='MEng Computer Science with Artificial Intelligence'
                        subtitle='University of Southampton, UK'
                        intro='I was awarded the degree of Master of Engineering with First Class Honours in Computer Science with Artificial Intelligence.'

                        content={
                            <div>
                                <Typography>
                                    Developed a sound-based location mapping research demonstration tool for MINDS CDT as part of my final year Group Design Project, supervised by Christine Evers.
                                </Typography>
                                <Typography>
                                    Completed Bachelor's Dissertation Project on image information extraction research (Computer Vision) and Deep Learning, supervised by Jonathon Hare,
                                    specifically analysis of existing CNN models' shape/texture bias and development of a shape biased CNN based classification model.
                                    The project was labelled prizeworthy by the team of examiners.
                                </Typography>
                                <Typography>
                                    Worked on individual and group (agile) development projects, such as an Airport Runway simulator,
                                    a client-server based sushi delivery service, developed a custom programming language and more.
                                </Typography>
                                <Typography>
                                    Took modules such as Algorithmics, Intelligent Systems, Advanced Machine Learning, Computer Vision, Deep Learning, Reinforcement Learning, Advanced Databases, Computational Biology, Computer Systems
                                    thus covering various aspects of Computer Science (with First class results).
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/UniversityOfSouthampton.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2009 - 2017</Typography>}
                    className={this.state.expanded === 'panel3' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel3'})}
                    onClick={() => this.setState({expanded : 'panel3'})}
                >
                    <TimelineContent
                        id='panel3'
                        title='Kecskeméti Bányai Júlia Gimnázium'
                        subtitle='Secondary School, Hungary'
                        intro='National exams (A-level equivalent) with A*A*A*A*A*A results'
                        content={
                            <div>
                                <Typography>
                                    Completed secondary school studies with specialisation in Mathematics, Physics and Informatics.
                                </Typography>
                                <Typography>
                                    Leading member of the school's Robotics society. Top results in several national and international
                                    Robotics team competitions.
                                </Typography>
                                <Typography>
                                    Completed studies with National Exams (Érettségi) in Mathematics, Physics, Informatics, History, Hungarian Literature and English.
                                </Typography>
                                <Typography>
                                    Awarded Bányai Júlia award for continuous academic excellence.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/banyai.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>
            </Timeline>
        );
    }
}

class ExperienceTimeline extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            expanded: 'panel1'
        };
    }

    render() {
        return(
            <Timeline className={`custom-timeline-1 ${this.props.screenSize}`} align={"left"} endless>
                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>
                        2022 - 2023
                    </Typography>}
                    className={this.state.expanded === 'panel2' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel2'})}
                    onClick={() => this.setState({expanded : 'panel2'})}
                >
                    <TimelineContent
                        id='panel2'
                        title='Webmaster at AAMAS 2023'
                        subtitle='22nd International Conference on Autonomous Agents and Multiagent Systems, UK'
                        intro="Part of the Local Organisers' team with Webmaster role"
                        content={
                            <div>
                                <Typography>
                                    {'Responsible for maintaining the AAMAS 2023 conference website at '}
                                    <Link target="_blank" rel="noopener noreferrer" href="https://aamas2023.soton.ac.uk">
                                        https://aamas2023.soton.ac.uk
                                    </Link>
                                </Typography>
                                <Typography>
                                    Connecting with the organisation team and different chairs, managing website content.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/AAMAS-2023.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>
                        2020 - 2021
                    </Typography>}
                    className={this.state.expanded === 'panel3' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel3'})}
                    onClick={() => this.setState({expanded : 'panel3'})}
                >
                    <TimelineContent
                        id='panel3'
                        title='Webmaster at AAMAS 2021'
                        subtitle='20th International Conference on Autonomous Agents and Multiagent Systems, UK'
                        intro="Part of the Local Organisers' team with Webmaster role"
                        content={
                            <div>
                                <Typography>
                                    {'Responsible for maintaining the AAMAS 2021 conference website at '}
                                    <Link target="_blank" rel="noopener noreferrer" href="http://aamas2021.soton.ac.uk">
                                        http://aamas2021.soton.ac.uk
                                    </Link>
                                </Typography>
                                <Typography>
                                    Connecting with the organisation team and different chairs, managing website content.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/AAMAS.jpeg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2020 Summer</Typography>}
                    className={this.state.expanded === 'panel4' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel4'})}
                    onClick={() => this.setState({expanded : 'panel4'})}
                >
                    <TimelineContent
                        id='panel4'
                        title='Research Summer Intern'
                        subtitle='University of Southampton, UK'
                        intro='Worked on a user annoyance aware preference elicitation framework for social robots'
                        content={
                            <div>
                                <Typography>
                                    Developed a user guide website for researchers at the University of Southampton working with the
                                    TIAGo robot. Filled up website with resources based on experience with the robot.
                                </Typography>
                                <Typography>
                                    Extended the research on user annoyance aware preference elicitation frameworks
                                    in collaboration with Long Tran-Thanh, Danesh S. Tarapore, William Yeoh and Christopher Amato.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/UniversityOfSouthampton.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2019 - 2020</Typography>}
                    className={this.state.expanded === 'panel5' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel5'})}
                    onClick={() => this.setState({expanded : 'panel5'})}
                >
                    <TimelineContent
                        id='panel5'
                        title='Undergraduate Research Assistant'
                        subtitle='University of Southampton, UK'
                        intro='Research projects on Human-Robot collaboration'
                        content={
                            <div>
                                <Typography>
                                    Continued the project on annoyance aware preference elicitation in the office environment.
                                    Ran several simulations on the framework testing its efficiency in multiple lifelike office scenarios.
                                    Made significant contributions towards the paper on this, which got accepted at IROS.
                                </Typography>
                                <Typography>
                                    One of the main people setting up and working on the university's TIAGo Steel robot.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/UniversityOfSouthampton.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>

                <Timeline.Item
                    time={<Typography variant='h5' className='timeline-date'>2019 Summer</Typography>}
                    className={this.state.expanded === 'panel6' ? 'timeline-item-selected' : false}
                    // onMouseEnter={() => this.setState({expanded : 'panel6'})}
                    onClick={() => this.setState({expanded : 'panel6'})}
                >
                    <TimelineContent
                        id='panel6'
                        title='Research Summer Intern'
                        subtitle='University of Southampton, UK'
                        intro='Annoyance aware preference elicitation in Human-Robot collaboration in the office environment'
                        content={
                            <div>
                                <Typography>
                                    Research project in collaboration with Long Tran-Thanh, Danesh S. Tarapore locally and  William Yeoh, Christopher Amato remotely.
                                </Typography>
                                <Typography>
                                    Worked on the TIAGo Steel robot by PAL Robotics using the Gazebo simulator.
                                    Implemented an office environment simulation with multiple office tasks performed by the robot.
                                    Developed and evaluated a user annoyance aware preference elicitation framework for the office environment using the ROS framework.
                                </Typography>
                                <Typography>
                                    This work provided the base for a later publication at the International Conference on Intelligent Robots and Systems (IROS).
                                </Typography>
                                <Typography>
                                    Also worked on the initialision of a robot hand for a separate project.
                                </Typography>
                            </div>
                        }
                        imgSrc='/images/UniversityOfSouthampton.jpg'
                        expanded={this.state.expanded}
                    />
                </Timeline.Item>
            </Timeline>
        );
    }
}

function EducationLine(){
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <Typography variant='h2' style={{flex: 1}}>
                Education
            </Typography>
            <Button href={process.env.PUBLIC_URL + 'documents/CV.pdf'} variant="outlined" color='default'  target={"_blank"} rel={"noopener noreferrer"} >
                <Image
                    src='/icons/download.png'
                    height='30px'
                />
                <Typography variant='button'>
                    Download CV
                </Typography>
            </Button>
        </div>
    );
}

function TimelineComponent2 () {
        return (
            <div>
                <Section
                    content={
                        <Container
                            style={{padding: '1rem'}}
                        >
                            <EducationLine/>
                            <br/>

                            <Hidden smDown>
                                <EducationTimeline screenSize='big'/>
                            </Hidden>
                            <Hidden mdUp>
                                <EducationTimeline screenSize='small'/>
                            </Hidden>
                        </Container>
                    }
                    id="education"
                    marginTop='4vh'
                    paddingTop='4vh'
                    paddingBottom='8vh'
                />
                <Section
                    content={
                        <Container
                            style={{padding: '1rem'}}
                        >
                            <Typography variant='h2'>
                                Experience
                            </Typography>
                            <br/>

                            <Hidden smDown>
                                <ExperienceTimeline screenSize='big'/>
                            </Hidden>
                            <Hidden mdUp>
                                <ExperienceTimeline screenSize='small'/>
                            </Hidden>
                        </Container>
                    }
                    id="experience"
                    paddingTop='4vh'
                    paddingBottom='8vh'
                />
            </div>
        );
}

export default TimelineComponent2;
