import React from 'react';
import {Container, Image} from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import './publications.css'
import Button from "@material-ui/core/Button";
import {Grid, Hidden, Typography} from "@material-ui/core";

function PublicationFigure(props){
    return(
        <div className='publication-figure'
             style={{textAlign: 'center'}}
        >
            <Hidden xsDown>
                <Image
                    style={{maxWidth: '100%'}}
                    src={props.imgSrc}
                />
            </Hidden>
            <Hidden smUp>
                <Image
                    style={{maxWidth: '290px'}}
                    src={props.imgSrc}
                />
            </Hidden>
        </div>
    );
}

function PublicationText(props){
    return(
        <div className='publication-text'>
            <Typography variant='h4'>{props.title}</Typography>
            <br/>
            <Typography>{props.content}</Typography>
            <br/>
            <Typography variant='h6' className='authors'>{props.authors}</Typography>
            <Typography variant='h6' className='conference'>{props.conference}</Typography>
            <Button variant="outlined" color='default' href={props.pdfLink} target={"_blank"} rel={"noopener noreferrer"}>
                <Typography variant='button'>PDF</Typography>
            </Button>
        </div>
    );
}

class Publication extends React.Component{
    render() {
        const grid_item = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        };
        return(
            <Container className='publication-container' style={{padding: '1rem'}}>
                <Paper className='publication' elevation={3}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">

                        <Grid item xs={12} md={4} style={grid_item}>
                            <PublicationFigure imgSrc={this.props.imgSrc}/>
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={1}/>
                        </Hidden>
                        <Grid item xs md={8} style={grid_item}>
                            <PublicationText
                                title={this.props.title}
                                content={this.props.content}
                                authors={this.props.authors}
                                conference={this.props.conference}
                                pdfLink={this.props.pdfLink}
                            />
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={1}/>
                        </Hidden>
                    </Grid>
                </Paper>
            </Container>
        );
    }
}

export class Publications extends React.Component{
    render() {
        return(
            <Container>
                <Typography variant='h2'>
                    Publications
                </Typography>
                <br/>
                <Publication
                    title='To Ask or Not to Ask: A User Annoyance Aware Preference Elicitation Framework for Social Robots'
                    authors='Balint Gucsi, Danesh S. Tarapore, William Yeoh, Christopher Amato, Long Tran-Thanh'
                    conference='IROS 2020, Las Vegas, USA'
                    content='In this paper we investigate how social robots can efficiently gather user preferences without exceeding the allowed user annoyance threshold. To do so, we use a Gazebo based simulated office environment with a TIAGo Steel robot. We then formulate the user annoyance aware preference elicitation problem as a combination of tensor completion and knapsack problems. We then test our approach on the aforementioned simulated environment and demonstrate that it can accurately estimate user preferences.'
                    imgSrc='/images/IROS_figures.jpg'
                    pdfLink='https://doi.org/10.1109/IROS45743.2020.9341607'
                />

                <Publication
                    title='Improving CNN by Increasing Shape Bias'
                    authors='Balint Gucsi, Supervisor: Jonathon Hare'
                    conference="Bachelor's Dissertation Project 2020"
                    content='Recent researches show that Convolutional Neural Networks (CNNs) tend to classify images based on their texture, rather than their shape as humans do, thus introducing a texture bias. This work attempts to reduce texture bias in CNNs to improve their robustness. The first part of this work reproduced the experiment proposed by Geirhos et al. This has shown that a CNN trained on a stylised database (without textural features) has increased shape bias in its classification. In the second part of the project several approaches for increasing the shape bias in CNNs were examined. Particularly those approaches were focused on which (contrary to currently used solutions) are not based on using an augmented (stylised) database but on using a different architecture or parameters. This included exploring models based on layers of the VGG-19 architecture, Difference of Gaussians filter and the role of initialising a model with pretrained weights. In order to evaluate the shape bias of the models, a set of evaluation databases were proposed. It was shown that it is possible to train CNNs to be shape biased to a certain extent using the examined models.'
                    imgSrc='/images/Thesis_combined_figures.jpg'
                    pdfLink='/documents/Improving_CNN_by_Increasing_Shape_Bias.pdf'
                />

            </Container>
        );
    }
}