import React from "react";
import {Container, Image} from "react-bootstrap";
import './welcome.css'
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, responsiveFontSizes, ThemeProvider} from "@material-ui/core/styles";
import {BesidesLoop} from "./BesidesLoop";
import ContactIcons from "./Contacts";
import posed from "react-pose";
import {Link} from "react-scroll";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";

const DownButtonContainer = posed.div({
    default: {
        y: 0,
        scale: 1,
    },
    active: {
        y: 10,
        transition: {duration: 300},
        scale: 1,
    }
});

class DownButton extends React.Component{
    constructor(props) {
        super(props);
        this.state = {isActive: false};
    }
    render() {
        return(
            <Link
                to="aboutme"
                spy={true}
                smooth={true}
                offset={-55}
                duration={750}
            >
                <DownButtonContainer
                    style={{
                        width: '60px',
                        justifyContent: 'center',
                        paddingBottom: '15px',
                        cursor: 'pointer'
                    }}
                    onMouseEnter={() => this.setState({isActive : true})}
                    onMouseLeave={() => this.setState({isActive : false})}
                    pose={this.state.isActive ? 'active' : 'default'}
                >
                    <Image src='/icons/expand.png' width='100%' />
                </DownButtonContainer>
            </Link>
        );
    }
}

let welcome_theme = createMuiTheme({
    typography: {
        h1:{//name
            fontFamily: 'Exo',
            color: '#424242',
            fontWeight: '600',
            fontSize: '125px'
        },
        h2:{//hello
            fontFamily: 'Exo',
            color: '#f57c00',
            fontWeight: '700',
            fontSize: '60px'
        },
        h3:{//course
            fontFamily: 'Exo',
            fontWeight: '500',
            fontSize: '60px'
        },
        h4:{//small
            fontFamily: 'Exo',
            fontWeight: '300',
            fontSize: '45px'
        },
        h5:{//besides
            fontFamily: 'Exo',
            fontWeight: '400',
            fontSize: '36px'
        }
    }
});
welcome_theme = responsiveFontSizes(welcome_theme, {
    breakpoints: ['xs','sm', 'md', 'lg','xl'],
    factor: 10
});

function WelcomeText(){
    return(
        <Container className='welcome-text'>
            <div className='welcome-text-hello-container'>
                <Typography className='welcome-hello' variant="h2">HELLO!</Typography>
                <Typography className='welcome-myname' variant="h4">My name is</Typography>
            </div>

            <Typography className='welcome-name' variant="h1">BALINT GUCSI</Typography>
            <Typography className='welcome-course' variant="h3">PhD student</Typography>
            {/*<Typography className='welcome-small' variant="h4">in</Typography>*/}
            <Typography className='welcome-course' variant="h3">in Artificial Intelligence</Typography>
            <Typography className='welcome-small' variant="h4">at the</Typography>
            <Typography className='welcome-course' variant="h3">MINDS CDT</Typography>
            <Typography className='welcome-course' variant="h3">University of Southampton</Typography>

            <span style={{height: '3vh'}}/>
            <BesidesLoop theme={welcome_theme}/>

            <span style={{height: '3vh'}}/>
            <ContactIcons/>
            <span style={{height: '3vh'}}/>

        </Container>
    );
}

function WelcomeImage(){
    return(
        <div>
            <Image src='/images/face.png' width='100%' />
        </div>
    );
}

export class Welcome extends React.Component{
    render() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        return(
            <ThemeProvider theme={welcome_theme}>

                <div className='welcome-page'>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{
                            flex: 10,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {/*Edge filler for large screens*/}
                        <Hidden mdDown>
                            <Grid item lg={1} />
                        </Hidden>
                        {/* Image shown in vertical position for small displays*/}
                        <Hidden mdUp>
                        <Grid item xs={6} sm={5} style={{
                            marginTop: '1rem',
                            marginBottom: '1rem'
                        }}>
                                <WelcomeImage />
                        </Grid>
                        </Hidden>

                        <Grid item xs={12} sm={12} md={8} lg={6}>
                            <WelcomeText/>
                        </Grid>

                        {/* Image shown in horisontal position for larger displays*/}
                        <Hidden smDown>
                            <Grid item md={4} style={{ padding: '2rem', maxWidth: '600px'}}>
                                <WelcomeImage />
                            </Grid>
                        </Hidden>
                        {/*Edge filler for large screens*/}
                        <Hidden mdDown>
                            <Grid item lg={1} />
                        </Hidden>

                    </Grid>

                    <Container className='down-button'>
                        <DownButton/>
                    </Container>
                </div>
            </ThemeProvider>
        );
    }
}
